import { currencyMap, dateMetaData } from '@/utils/pay'

export default {
  methods: {
    utilDate() {
      let today = new Date()
      let dd = String(today.getDate()).padStart(2, '0')
      let mmString = today.toLocaleString('en-US', { month: 'long' })
      let yyyy = today.getFullYear()
      return mmString + ' ' + dd + ', ' + yyyy
    },
    formatPrice(price, currency, locale) {
      if (currency === 'IDR') {
        return `${currencyMap[currency]} ${price}`
      }
      if (!price || !currency) {
        return
      }
      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency
      })
      return formatter.format(price)
    },
    utilComputedSubDate(unit, num, language, slot = '-') {
    // let language = (navigator.language).substr(0, 2)
      let subDate = '--'
      let langLocal = dateMetaData[language]
      if (!langLocal) {
        langLocal = dateMetaData['en']
      }

      let time = langLocal[(unit)]
      if (time) {
        subDate = num + slot + time
      }

      //  续订复数套餐
      if (num > 1 && language !== 'zh') {
        subDate += 's'
      }
      //  永久套餐
      if (num > 50 && unit === 5) {
        subDate = dateMetaData[language]['lifetime']
      }
      return subDate
    }
  }
}
