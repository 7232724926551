<template>
  <div>
    <div class="checkout-view" v-if="!waitLoading">
      <div>
        <!-- <img src="@/assets/images/pay/icon-close.png" class="close-icon" @click="closePayment" /> -->
        <div class="title">
          <div class="flex-center">
            <img src="@/assets/images/pay/payments/payment.png" alt="" />
            {{ appName }}
          </div>
          <el-popover
            placement="bottom-end"
            class="tips-box"
            width="340"
            trigger="click"
          >
            <div class="title-tips" slot="reference">
              <span
                class="price-title"
                v-html="
                  $t('tips.tipsInfo.title', {
                    price: formatPrice(
                      productsPayInfo.priceInfo.showPrice,
                      productsPayInfo.priceInfo.currency,
                      $i18n.locale
                    ),
                  })
                "
              />
              <img class="pay-icon" src="@/assets/images/pay/down.png" />
            </div>
            <div class="dialog-box">
              <div class="desc-info">
                <div class="desc-item flex-space-between-center">
                  <span class="label">{{ payList.productsInfo?.title }}</span>
                </div>
                <div class="desc-item">
                  <p class="desc bold">
                    {{ $t("tips.tipsInfo.paymentTitle") }}
                  </p>
                  <p class="desc detail-desc">
                    {{
                      $t("tips.tipsInfo.paymentDes", {
                        price: formatPrice(
                          productsPayInfo.priceInfo.price,
                          productsPayInfo.priceInfo.currency,
                          $i18n.locale
                        ),
                        subDate: utilComputedSubDate(
                          productsPayInfo.priceInfo["durationUnit"],
                          productsPayInfo.priceInfo["duration"],
                          $i18n.locale
                        ),
                      })
                    }}
                  </p>
                  <div class="desc-tag">
                    <p class="desc">
                      {{
                        $t("tips.tipsInfo.paymentTag", {
                          price: formatPrice(
                            productsPayInfo.priceInfo.showPrice,
                            productsPayInfo.priceInfo.currency,
                            $i18n.locale
                          ),
                          currentDay: utilDate(),
                        })
                      }}
                    </p>
                  </div>
                </div>
                <div class="desc-item flex-space-between-center">
                  <p class="desc">{{ payInfo.productsInfo.title }}</p>
                  <p class="desc">
                    {{
                      formatPrice(
                        productsPayInfo.priceInfo.showPrice,
                        productsPayInfo.priceInfo.currency,
                        $i18n.locale
                      )
                    }}
                  </p>
                </div>
                <div class="desc-item flex-space-between-center">
                  <p class="desc">{{ $t("tips.tipsInfo.total") }}</p>
                  <p class="title">
                    {{
                      formatPrice(
                        productsPayInfo.priceInfo.showPrice,
                        productsPayInfo.priceInfo.currency,
                        $i18n.locale
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </el-popover>
        </div>
        <div class="banner-box">
          <div class="email-banner pay-account">
            <img src="@/assets/images/pay/logo-pc.png" />
          </div>
        </div>

        <div class="pay-form-main">
          <div class="input" v-for="(input, idx) in inputList" :key="idx">
            <img :src="input.icon" />
            <input
              :disabled="input.preFillingValue ? true : false"
              :key="input.code"
              :class="['font-16']"
              @change="checkedInputFun"
              v-model="formData[input.code]"
              :placeholder="input.placeholderMessage"
            />
            <span class="error-tip">{{ input.errorTip }}</span>
          </div>
        </div>
        <div
          class="pay-checkout-list"
          v-if="payInfo.paymentMethodList.length > 0"
        >
          <template v-for="(item, idx) in payInfo.paymentMethodList">
            <cardPay
              :key="item.code"
              v-if="item.code === 'card'"
              :payFormData="formData"
              :isChecked="isChecked"
              @goPay="goPay"
              :payList="payList"
              :checkedInput="checkedInput"
              @checkeFormData="checkedInputFun"
              :cardInfo="item"
            />
            <div
              class="pay-info-tips"
              :key="item.code+idx"
              v-if="noCard && idx === 0"
            >
              <div class="pay-info-tips-title">
                {{ $t("payDialog.paymentMethod") }}
              </div>
            </div>
            <div
              class="pay-checkout-item"
              :key="item.code"
              v-if="item.code !== 'card'"
              :class="[{ 'pay-checkout-checked': item.code === checkedPay }]"
              @click="checkedPayGo(item.code)"
            >
              <div class="pay-checkout-item-price">
                {{
                  formatPrice(
                    item.priceInfo.showPrice,
                    item.priceInfo.currency,
                    $i18n.locale
                  )
                }}
                <span v-if="item.priceInfo.upRate > 0">
                  {{
                    formatPrice(
                      item.priceInfo.lowestPrice,
                      item.priceInfo.currency,
                      $i18n.locale
                    )
                  }}</span
                >
              </div>
              <div class="pay-checkout-item-icon">
                <img :src="getPayIcon(item.code)" />
              </div>
              <div class="pay-checkout-tag" v-if="item.priceInfo.upRate > 0">
                {{ item.priceInfo.upRate.toFixed(0)
                }}{{ $t("payDialog.footerTagOff") }}
              </div>
            </div>
          </template>
        </div>
        <button
          :class="['button_pay_primary', 'font-18']"
          v-if="!noCard"
          :style="{
            background: checkedPay && isChecked ? '' : '#C8C9C8',
            cursor: isChecked ? '' : 'not-allowed',
            opacity: isChecked ? '' : 1,
          }"
          :data-name="formPayMethod"
          @click="handlePay(checkedPay)"
        >
          <template v-if="productsPayInfo.priceInfo.offerType !== 0">
            {{ $t("btn.subscribeNow") }}
          </template>
          <template v-if="productsPayInfo.priceInfo.offerType === 0">
            {{ $t("btn.PayNow") }}
          </template>
          <!-- <span>
            {{ formatPrice(productsPayInfo.priceInfo.showPrice, productsPayInfo.priceInfo.currency, $i18n.locale) }}
          </span> -->
        </button>
        <tipsShow
          :configTips="configTips"
          :priceInfo="productsPayInfo"
          v-model="isChecked"
          :showErrorChecked="showErrorChecked"
          @change="changeError"
        />
      </div>
    </div>
    <div v-if="waitLoading" id="wait-loading" class="wait-loading">
      <div ref="lottieContainer" class="wait-loading-box"></div>
    </div>
  </div>
</template>

<script>
import {
  payIcon,
  uuid,
  environmentInformation,
  validateFunc,
} from "@/utils/pay";
import tipsShow from "./tipsShow";
import payMixins from "@/mixin/pay";
import { paymentApi } from "@/api/pay";
import cardPay from "./cardPay.vue";

export default {
  name: "Center",
  mixins: [payMixins],
  components: { tipsShow, cardPay },
  data() {
    return {
      checkedInput: false,
      payList: {},
      showErrorChecked:false,
      waitLoading: false,
      payType: "default",
      showLookup: false,
      phoneLookup: {},
      inputList: [],
      formData: {},
      formPayMethod: "",
      checkedPay: "",
      isChecked: false,
      loadingShow: null,
      payInfo: {
        productsInfo: {},
        paymentMethodList: [],
      },
    };
  },
  computed: {
    noCard() {
      const noCard = this.payInfo.paymentMethodList.filter(
        (res) => res.code === "card"
      );
      console.log(noCard,this.payInfo.paymentMethodList,this.payInfo,"noCard>>>")
      return noCard.length > 0;
    },
    paymentLink() {
      const findItem = this.payInfo.paymentMethodList.find(
        (res) => res.code === this.checkedPay
      );
      const payItemBox = JSON.parse(JSON.stringify(this.payList));
      delete payItemBox.paymentMethodList;
      let paymentItem = JSON.parse(
        JSON.stringify({
          ...findItem,
          ...payItemBox,
          isSandbox: true,
          metadata: {},
        })
      );
      delete paymentItem.paymentFormList;
      paymentItem.paymentInfo = {};
      findItem.paymentFormList.forEach((formItem) => {
        paymentItem.paymentInfo[formItem.code] =
          this.formData[formItem.code] || formItem.preFillingValue || "";
      });
      if (this.formData.userEmail) {
        paymentItem.paymentInfo.email = this.formData.userEmail;
      }
      let paymentLinkJson = btoa(JSON.stringify(paymentItem));
      paymentLinkJson =
        paymentLinkJson.slice(0, 1) + "=" + paymentLinkJson.slice(1);
      return findItem.paymentLink + `?${paymentLinkJson}`;
    },
    productsPayInfo() {
      const payInfo =
        this.payInfo.paymentMethodList.find(
          (res) => res.code === this.checkedPay
        ) || {};
      return {
        priceInfo: {},
        payMethod: {},
        descrInfo: {},
        ...payInfo,
      };
    },
  },
  props: {
    orderId: {
      type: String,
      default: "",
    },
    appName: {
      type: String,
      default: "",
    },
    configTips: {
      type: Object,
      default: () => {
        return {};
      },
    },
    productsInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dialogArgs: {
      type: Object,
      default: () => {
        return {};
      },
    },
    lang: {
      type: Object,
      default: () => {
        return {
          hrefLang: "",
        };
      },
    },
  },
  mounted() {
    this.paymentInfo();
  },
  methods: {
    changeError(val){
      this.showErrorChecked = !val
    },
    checkedPayGo(code) {
      if (this.noCard) {
        this.checkedInputFun();
        if (!this.isChecked) {
          this.showErrorChecked = true;
          return;
        }
        return this.handlePay(code);
      }
      this.checkedPay = code;
    },
    checkedInputFun() {
      this.checkedInput = validateFunc({
        form: this.formData,
        inputList: this.inputList,
      });
    },
    getPayIcon(code) {
      return payIcon(code);
    },
    async paymentInfo() {
      this.loadingShow = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.waitLoading = true;
      // uuid  作为支付唯一标识使用
      let webId = localStorage.getItem("uuid");
      if (!webId) {
        webId = +new Date() + uuid(16, 16);
        localStorage.setItem("uuid", webId);
      }
      let email = "";
      const userInfo = localStorage.getItem("userInfo");
      if (userInfo) {
        const userInfoJson = JSON.parse(userInfo);
        if (userInfoJson.email) {
          email = userInfoJson.email;
        }
      }
      const params = {
        email,
        phoneNumber: "",
        productsId: this.orderId,
        marketing:
          this.$route.query.channel || localStorage.getItem("channel") || "SEO",
        successUrl: `${this.$origin("/s/payCallBack")}`,
        cancelUrl: `${this.$origin("/s/payCallBack")}`,
        metadata: {
          country_code: this.$i18n.locale,
          gaCid: "",
        },
      };
      params.environment = await environmentInformation();
      const payList = await paymentApi(params);
      this.payList = payList.data;
      this.init();
    },
    async init() {
      let payList = JSON.parse(JSON.stringify(this.payList));
      const payItemBox = JSON.parse(JSON.stringify(payList));
      delete payItemBox.paymentMethodList;
      payList.paymentMethodList = payList.paymentMethodList.map((res) => {
        res.priceInfo.showPrice = res.priceInfo.price;
        if (res.priceInfo.offerType === 1) {
          res.priceInfo.showPrice = res.priceInfo.tryoutPrice;
        }
        if (res.code !== "card") {
          let paymentItem = JSON.parse(
            JSON.stringify({
              ...res,
              ...payItemBox,
              isSandbox: true,
              metadata: {},
            })
          );
          paymentItem.paymentInfo = {};
          paymentItem.paymentFormList.forEach((formItem) => {
            paymentItem.paymentInfo[formItem.code] =
              formItem.preFillingValue || "";
          });
          res.paymentLink = res.paymentLink;
        }
        return res;
      });
      let paymentFormList = [];
      payList.paymentMethodList.forEach((res) => {
        res.paymentFormList.forEach((ret) => {
          if (ret.code === "userEmail") {
            paymentFormList.push({
              code: "userEmail",
              autocomplete: "email",
              icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAA9BJREFUWEftmF1oW2UYx///JG2XzVVmHShCB174gQoTpiDCoFPwQgR3seBHTVs21jTiuqbthpswoZOxJW3aYZMG/OjpuilBwSH0alBlzqsO8UKYiBfOXcimE1dNtes5fzlZm7ZJupOkydqL5jLP1+99nvd93vc5xCr/cZXzYQ1wuRXKZLAvmfSa16YOgnqe4lZBG5brvBh7kr9D+h7kp51t/g9JyrZPA/YlRp+wzJkvJDxYjNOK6RIXat3rX2xt9f3F8MjIBvyti5AerljAkhzzbFfQv5OR2PA7EnpK8lFhIxe4g5FB42tB2yscqyT3dHGA4dhwlOBbktwleamUEXnO7dH+9CEJx0cep2X1C3iuUvEK9kv+7HahMxRoOps5xXPGkdiplwGzd0VOMzEJ8L0t93qjPp9veo4p5yY5OTZWM/3LtQ5JhyBsLHjlJSrO9rthr9d76M0W32/ZbhiNjz7b0dZ4IVsw+HHyvqlU6hjIJkkVuRJJfutysT0U8E9kx498cHoL/7u5i+FB4yaJzz1uHNjf2nQ5R3Fw9CnBHAD0TIlJymPGK27yYCjoP5MtTPflSdk3WjfFiTQgIA/BKQEnaj33nGhtfSm10NDOYO/Q6GuQdVzSA6WC2jFAhTe6644XEoPgNxnA+aC8QrcOdAWaP8kGSSS+XD9p/vm2YHVBWFcMKMGkx4PuYqq0BOCtsE77A9MzYUm7nCBJfueCqz0UfON8tm40cep+07SOAfDn2+e3BZyFtF8US56wyNDwdpkYALA15/SRVwEc7mzzf0TSWiif6xSwcFjQXUst0hEwY0hMUq6j9ZvX9S/sUbZckqs3buyRcBTAZpB2DztZU+vu2dfYeCM7eF/c2GlJkUJ6bRowEjNmCr7msrr8wuCJRPLuG2YqWO2q+qw98PpPOWDpJ53ZL2mH07aYb9J5D0kB5rP3ZGhv8w9O2u8bRt3UP+whtLfgRMw6LbzE+ToZaVKIs8Z7JLTHdz1bZXx83DNx6dcgpHchbXJaSD75sgAzZSCuizyy7ZH6oYaGhhn7/0h85AVJUUiPlgK2qMRF7cHbRCNwSeQYhW3lel+WJYPLyZCT7RqgU4ac5GsZdMqQk3zVZxDEeXvs/FHQQ06rWQk5ScMGPCPo1ZUAcIpJch/tJxMsflWpucMJYmk5/6iqrnrs1lwcM3ohhUp3Vl5Le9IT9Up3W3MyDWhnr2/ICMhi+E5/dstZGnnZA+zuCDads2WLxslY7PSmFM2nAT15J2biRXDCVXhwsa66fqKlpeHf+QdDeatTdm8VGcjLSbnqAf8HbVbEBbq/0ygAAAAASUVORK5CYII=",
              isRequired: true,
              allowRewrite: false,
              validationType: "email",
              placeholderMessage: this.$t("message.emailPlaceholder"),
              warnMessage: this.$t("message.emailError"),
              errorTip: "",
              ...ret,
            });
          } else {
            paymentFormList.push(res);
          }
        });
      });
      const removeDuplicateObjects = (arr, prop) => {
        const seen = {};
        return arr.filter((obj) => {
          const uniqueProp = obj[prop];
          return seen.hasOwnProperty(uniqueProp)
            ? false
            : (seen[uniqueProp] = true);
        });
      };
      this.payInfo = Object.assign(
        {
          paymentFormList: removeDuplicateObjects(paymentFormList, "code"),
        },
        payList
      );
      if (this.payInfo.paymentMethodList.length > 0) {
        this.checkedPay = this.payInfo.paymentMethodList[0].code;
      }
      const listinfo = this.payInfo.paymentFormList
        .map((item) => {
          if (item.preFillingValue) {
            this.formData[item.code] = item.preFillingValue;
          } else {
            this.formData[item.code] = this.formData[item.code];
          }
          return {
            ...item,
            isValidated: Boolean(this.formData[item.code]),
            errorTip: "",
          };
        })
        .filter((res) => res.code !== "card");
      this.inputList = listinfo;
      this.waitLoading = false;
      this.loadingShow.close();
    },
    handlePay(code) {
      this.checkedInputFun();
      if (!this.checkedInput) {
        return;
      }
      if (this.checkedPay !== code) {
        this.checkedPay = code;
      }
      this.goPay(this.paymentLink);
    },
    goPay(paymentLink) {
      window.open(paymentLink, "_blank", "noopener,noreferrer");
      this.$emit("goPay", this.payInfo.retrieveCode);
    },
    closePayment() {},
  },
};
</script>

<style scoped lang="scss">
.checkout-view {
  :deep(.highlight) {
    color: var(--primary-color);
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 2s;
}
.flex-space-between-center {
  display: flex;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 992px) {
  .checkout-view {
    width: 420px;
    background: #fff;
    border-radius: 24px 24px 24px 24px;
    padding: 12px 32px;
    padding-bottom: 24px;
    position: relative;
    max-height: 90vh;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .close-icon {
      width: 36px;
      cursor: pointer;
      position: absolute;
      right: 16px;
      top: 16px;
    }

    .title {
      // display: flex;
      // align-items: center;
      font-weight: bold;
      font-size: 20px;
      color: #0c0c0d;
      line-height: 38px;
      gap: 15px;
      padding-bottom: 40px;
      display: flex;
      justify-content: space-between;
      img {
        width: 48px;
        margin-right: 11px;
      }
      .title-tips {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        .pay-icon {
          width: 35px;
          height: 35px;
          margin-left: 10px;
        }
      }
    }

    .des {
      padding-top: 6px;
      padding-bottom: 13px;
    }
    .email-banner {
      width: 60%;
      position: relative;
      margin: 0 auto;
      .email-title {
        position: absolute;
        top: 10px;
        text-align: center;
        width: 100%;
        color: var(--primary-color);
        font-style: normal;
        font-size: 37px;
      }

      &.gps-locked {
        .email-title {
          font-size: 29px;
          text-align: left;
          padding-left: 11px;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          width: auto;
          background-image: linear-gradient(0deg, #ff5151 0%, #ff713e 100%);
        }
      }

      img {
        width: 100%;
      }

      .more-lookup {
        position: absolute;
        bottom: 25px;
        right: 11px;
        width: 156px;
        height: 132px;
        text-align: center;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 8px;
        padding: 6px 12px;

        #more-lookup-loader {
          border: 2px solid #c2c6cc;
          border-top: 2px solid var(--primary-color);
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: inline-block;
          animation: spin 1.5s linear infinite;
          position: absolute;
          bottom: 0;
          right: 0;
          top: 0;
          left: 0;
          margin: auto;
        }

        .lookup-title {
          font-family: Roboto, Roboto;
          font-weight: 600;
          font-size: 14px;
          color: var(--primary-color);
        }

        .lookup-des {
          font-family: Roboto, Roboto;
          font-weight: 400;
          font-size: 8px;
          color: #919599;
          margin-top: 2px;
        }

        .lookup-box {
          margin-top: 10px;

          .location-tag {
            display: flex;
            align-items: center;

            &::before {
              content: "";
              display: inline-block;
              // background: url("@/assets/images/pay/location-tag.svg") no-repeat
              //   center;
              width: 12px;
              height: 12px;
              margin-right: 8px;
              background-size: contain;
            }
          }

          .lookup-location {
            filter: blur(3px);
          }

          .loader {
            border: 2px solid #c2c6cc;
            border-top: 2px solid var(--primary-color);
            border-radius: 50%;
            width: 15px;
            height: 15px;
            display: inline-block;
            animation: spin 1.5s linear infinite;
          }

          .lookup-item {
            display: flex;
            justify-content: space-between;
            align-content: center;
            font-size: 9px;
            margin-bottom: 8px;

            .left {
              color: #919599;
            }

            .right {
              color: #616366;
            }
          }
        }
      }
    }

    .pay-info-tips {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      padding: 12px 0;
      padding-top: 0;
      .pay-info-tips-title {
        font-weight: 400;
        font-size: 14px;
        color: #919599;
        background: #fff;
        text-align: center;
        padding: 0 8px;
        position: relative;
        z-index: 10;
        display: inline-block;
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: #dadef2;
        position: absolute;
        top: -10px;
        bottom: 0;
        margin: auto;
        z-index: 4;
      }
    }

    .pay-checkout-list {
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin-top: 6px;
      padding-bottom: 10px;
      .pay-checkout-item {
        background: #ffffff;
        border-radius: 10px;
        border: 2px solid #c2c6cc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 9px 24px;
        position: relative;
        cursor: pointer;

        .pay-checkout-item-list {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .pay-list {
          display: flex;
          gap: 10px;

          img {
            width: 38px;
          }
        }

        .more-methods {
          &::after {
            content: "● ● ●";
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            font-size: 20px;
            color: #ccc;
            display: flex;
            align-items: center;
            padding: 0 32px;
          }
        }

        .pay-checkout-tag {
          position: absolute;
          top: -11px;
          display: inline-block;
          left: 22px;
          font-weight: bold;
          font-size: 14px;
          color: #ffffff;
          padding: 0 11px;
          height: 22px;
          background: #919599;
          border-radius: 21px;
        }

        .pay-checkout-item-price {
          font-weight: 800;
          font-size: 14px;
          color: #616366;
          display: flex;
          align-items: center;

          span {
            text-decoration-line: line-through;
            font-size: 12px;
            margin-left: 10px;
            color: #919599;
          }
        }

        .pay-checkout-item-icon {
          height: 24px;
          text-align: right;
          display: flex;

          img {
            height: 100%;
          }
        }

        &.pay-checkout-checked {
          border-color: var(--primary-color);

          .pay-checkout-tag {
            color: #ffffff;
            background: var(--primary-color);
          }

          .pay-checkout-item-price {
            color: #0c0c0d;
          }
        }
      }
    }

    .pay-checkout-tips {
      font-size: 13px;
      color: #919599;
      padding-top: 8px;
      padding-bottom: 22px;
    }

    .pay-checkout-btn {
      width: 100%;
      height: 49px;
      background: var(--primary-color);
      border-radius: 36px 36px 36px 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 18px;
      color: #ffffff;
    }

    .safety-tips-box {
      padding-top: 36px;
      display: flex;
      align-items: center;
      justify-content: center;

      .safety-item {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex: 1;

        &::before {
          content: "";
          display: block;
          width: 1px;
          height: 80%;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background: #dadef2;
        }

        &:last-child {
          &::before {
            display: none;
          }
        }

        img {
          width: 36px;
          height: 36px;
          margin-right: 6px;
        }

        .safety-item-box {
          color: #919599;

          .safety-item-title {
            font-size: 13px;
            line-height: 20px;
          }

          .safety-item-des {
            font-size: 10px;
          }
        }
      }
    }
  }

  .pay-form-main {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    margin-top: 10px;
    padding-bottom: 20px;
    .input {
      position: relative;

      img {
        width: 26px;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }

      input {
        width: 100%;
        padding: 14px 0;
        text-indent: 50px;
        border: 1px solid #e2e2e2;
        border-radius: 8px;
        margin: 0 -4px;
        &:focus-visible {
          outline: none;
        }
      }

      input:disabled {
        cursor: not-allowed;
      }

      .error-tip {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -18px;
        font-size: 12px;
        font-family: Manrope-Regular, Manrope;
        font-weight: 400;
        line-height: 16px;
        color: #e94053;
      }
    }
  }

  .font-16 {
    font-size: 16px;
  }

  .font-18 {
    font-size: 18px;
  }

  .button_pay_primary {
    display: block;
    border: none;
    width: 100%;
    border-radius: 8px;
    padding: 13px;
    margin-bottom: 12px;
    font-family: Manrope-Medium, Manrope;
    font-weight: 500;
    line-height: 21px;
    color: #ffffff;
    background: #121212;
    &:hover {
      background: #182836;
    }
  }
  .dialog-box {
    width: 300px;
    .desc-info {
      color: #333333;

      .detail-desc {
        margin: 12px 0;
      }

      .desc-tag {
        display: inline-block;
        padding: 10px 15px;
        background: #f2f7ff;
        border-radius: 8px;
        border: 1px solid #4183fb;
      }

      .desc-item {
        padding: 12px;
        .desc,
        span,
        .title {
          font-weight: bold;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #e2e2e2;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .tips-box {
    padding: 0 !important;
  }
  .checkout-view {
    height: 100%;
    background: #fff;
    padding: 0 0.4rem;
    padding-bottom: 0.2rem;
    position: relative;
    overflow: auto;
    scrollbar-width: none;
    /* firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 0 !important;
      display: none;
      /* Hide scrollbar in Webkit browsers (e.g., Chrome, Safari) */
    }

    .close-icon {
      width: 0.72rem;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0.1rem;
      right: 0.22rem;
    }

    .title {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      font-weight: bold;
      font-size: 0.48rem;
      color: #0c0c0d;
      line-height: 0.56rem;
      gap: 0.14rem;
      padding-top: 0.22rem;
      padding-bottom: 0.4rem;

      img {
        width: 0.96rem;
        margin-right: 0.22rem;
      }
      .title-tips {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.32rem;
        .pay-icon {
          width: 0.7rem;
          height: 0.7rem;
          margin-left: 0.2rem;
        }
      }
    }

    .des {
      padding-top: 0.12rem;
      padding-bottom: 0.14rem;
      font-size: 0.28rem;
    }

    .banner-box {
      width: 60%;
      margin: 0 auto;
    }

    .email-banner {
      width: 100%;
      position: relative;

      .email-title {
        position: absolute;
        top: 0.08rem;
        text-align: center;
        width: 100%;
        // color: #0c0c0d;
        color: var(--primary-color);
        font-style: normal;
        font-size: 0.6rem;
      }

      &.gps-locked {
        .email-title {
          font-size: 0.47rem;
          text-align: left;
          padding-left: 0.18rem;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          width: auto;
          background-image: linear-gradient(0deg, #ff5151 0%, #ff713e 100%);
        }
      }

      img {
        width: 100%;
      }

      .more-lookup {
        position: absolute;
        bottom: 0.2rem;
        right: 0rem;
        width: 3.12rem;
        height: 2.64rem;
        text-align: center;
        background: #ffffffb3;
        border-radius: 0.16rem;
        padding: 0.12rem 0.24rem;
        transform: scale(0.9);

        #more-lookup-loader {
          border: 0.04rem solid #c2c6cc;
          border-top: 0.04rem solid var(--primary-color);
          border-radius: 50%;
          width: 0.6rem;
          height: 0.6rem;
          display: inline-block;
          animation: spin 1.5s linear infinite;
          position: absolute;
          bottom: 0;
          right: 0;
          top: 0;
          left: 0;
          margin: auto;
        }

        .lookup-title {
          font-family: Roboto, Roboto;
          font-weight: 600;
          font-size: 0.28rem;
          color: var(--primary-color);
        }

        .lookup-des {
          font-family: Roboto, Roboto;
          font-weight: 400;
          font-size: 0.16rem;
          color: #919599;
          margin-top: 0.04rem;
        }

        .lookup-box {
          margin-top: 0.2rem;

          .location-tag {
            display: flex;
            align-items: center;

            &::before {
              content: "";
              display: inline-block;
              // background: url("@/assets/images/pay/location-tag.svg") no-repeat
              //   center;
              width: 12px;
              height: 12px;
              margin-right: 8px;
              background-size: contain;
            }
          }

          .lookup-location {
            filter: blur(0.007rem);
          }

          .loader {
            border: 0.02rem solid #c2c6cc;
            border-top: 0.02rem solid var(--primary-color);
            border-radius: 50%;
            width: 0.3rem;
            height: 0.3rem;
            display: inline-block;
            animation: spin 1.5s linear infinite;
          }

          .lookup-item {
            display: flex;
            justify-content: space-between;
            align-content: center;
            font-size: 0.18rem;
            margin-bottom: 0.16rem;

            .left {
              color: #919599;
            }

            .right {
              color: #616366;
            }
          }
        }
      }
    }

    .pay-info-tips {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      padding: 0.42rem 0;
      padding-bottom: 0.22rem;

      .pay-info-tips-title {
        font-weight: 400;
        font-size: 0.26rem;
        color: #919599;
        background: #fff;
        text-align: center;
        padding: 0 0.16rem;
        position: relative;
        z-index: 10;
        display: inline-block;
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: #dadef2;
        position: absolute;
        top: 0.2rem;
        bottom: 0;
        margin: auto;
        z-index: 4;
      }
    }

    .pay-checkout-list {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      margin-top: 0.2rem;
      padding-bottom: 0.2rem;
      .pay-checkout-item {
        background: #ffffff;
        border-radius: 0.16rem;
        border: 2px solid #c2c6cc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 0.48rem;
        padding-right: 0.2rem;
        position: relative;
        cursor: pointer;
        height: 1.08rem;

        &.pay-checkout-item-list {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .pay-list {
          display: flex;
          gap: 0.2rem;

          img {
            width: 1.36rem;
          }
        }

        .more-methods {
          &::after {
            content: "● ● ●";
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            font-size: 0.4rem;
            color: #ccc;
            display: flex;
            align-items: center;
            padding: 0 0.64rem;
            padding-right: 0.2rem;
          }
        }

        .pay-checkout-tag {
          position: absolute;
          top: -0.22rem;
          display: inline-block;
          left: 0.44rem;
          font-weight: bold;
          font-size: 0.28rem;
          color: #ffffff;
          padding: 0 0.22rem;
          height: 0.44rem;
          background: #919599;
          border-radius: 0.42rem;
        }

        .pay-checkout-item-price {
          font-weight: 800;
          font-size: 0.48rem;
          color: #616366;
          display: flex;
          align-items: center;

          span {
            color: #919599;
            text-decoration-line: line-through;
            font-size: 0.24rem;
            margin-left: 0.2rem;
          }
        }

        .pay-checkout-item-icon {
          height: 0.56rem;
          text-align: right;
          display: flex;

          img {
            height: 100%;
          }
        }

        &.pay-checkout-checked {
          border-color: var(--primary-color);

          .pay-checkout-tag {
            color: #ffffff;
            background: var(--primary-color);
          }

          .pay-checkout-item-price {
            color: #0c0c0d;
          }
        }
      }
    }

    .pay-checkout-tips {
      font-size: 0.26rem;
      color: #919599;
      padding-top: 0.24rem;
      padding-bottom: 0.4rem;
    }

    .pay-checkout-btn {
      width: 100%;
      height: 0.96rem;
      background: var(--primary-color);
      border-radius: 0.72rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 0.36rem;
      color: #ffffff;
    }

    .safety-tips-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.2rem;
      margin-top: 0.2rem;

      .safety-item {
        flex: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &::before {
          content: "";
          display: block;
          width: 1px;
          height: 0.4rem;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          background: #dadef2;
        }

        &:last-child {
          &::before {
            display: none;
          }
        }

        img {
          width: 0.64rem;
          height: 0.64rem;
          margin-bottom: 0.1rem;
        }

        .safety-item-box {
          color: #919599;
          text-align: center;

          .safety-item-title {
            font-size: 0.26rem;
            line-height: 0.4rem;
          }

          .safety-item-des {
            font-size: 0.2rem;
          }
        }
      }
    }

    .button_pay_primary {
      display: block;
      border: none;
      width: 100%;
      border-radius: 0.08rem;
      padding: 0.2rem;
      margin-top: 0.4rem;
      margin-bottom: 0.2rem;
      font-size: 0.36rem;
      font-family: Manrope-Medium, Manrope;
      font-weight: 500;
      color: #ffffff;
      background: #121212;

      &:hover {
        background: #182836;
      }
    }
  }

  .font-16 {
    font-size: 0.32rem;
    font-family: Manrope-Regular, Manrope;
    font-weight: 400;
  }

  .font-18 {
    font-size: 0.36rem;
    font-family: Manrope-Regular, Manrope;
    font-weight: 400;
  }

  .pay-form-main {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-top: 0.2rem;
    padding-bottom: 0.2rem;

    .input {
      position: relative;

      img {
        width: 0.44rem;
        position: absolute;
        left: 0.3rem;
        top: 50%;
        transform: translateY(-50%);
      }

      input {
        display: block;
        width: 100%;
        padding: 0.2rem 0;
        border: 1px solid #e2e2e2;
        border-radius: 8px;
        text-indent: 0.93rem;

        &:focus-visible {
          outline: none;
        }
      }

      input:disabled {
        cursor: not-allowed;
      }

      .error-tip {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -0.36rem;
        font-size: 0.24rem;
        font-family: Manrope-Regular, Manrope;
        font-weight: 400;
        line-height: 0.32rem;
        color: #e94053;
      }
    }
  }
  .dialog-box {
    // position: absolute;
    // z-index: 1000;
    background: #fff;
    left: 0;
    width: 100%;
    right: 0;
    margin: auto;
    top: 80px;
    border: 1px solid #dedede;
    border-radius: 10px;
    text-align: left;

    border-radius: 0.08rem;
    border: 1px solid #e9ebf0;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
    .close {
      width: 24px;
    }
    .desc-info {
      color: #333333;

      .detail-desc {
        margin: 12px 0;
      }

      .desc-tag {
        display: inline-block;
        padding: 10px 15px;
        background: #f2f7ff;
        border-radius: 8px;
        border: 1px solid #4183fb;
      }

      .desc-item {
        padding: 12px;

        &:not(:last-child) {
          border-bottom: 1px solid #e2e2e2;
        }
      }
    }
  }
}
</style>
