<template>
  <div class="dialog-warp">
    <el-dialog
      class="payment-warp"
      :visible.sync="visible"
      width="490px"
      top="0"
    >
      <center
        v-if="showPayment"
        :appName="paymentConfig.appName"
        :configTips="paymentConfig.configTips"
        @goPay="goPay"
        :orderId="orderId"
      />
    </el-dialog>
  </div>
</template>
<script>
import center from "./pay/center";
import { checkOrder } from "@/api/pay";
import { $extractDomain } from "@/utils/index";
export default {
  name: "payment",
  data() {
    return {
      paymentConfig: {
        appName: "",
        configTips: {},
      },
      paymenCount: 0,
      loadingShow: false,
      showPayment: true,
    };
  },
  components: { center },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: "",
    },
  },
  computed: {
    visible: {
      get() {
        return this.value && this.showPayment;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.paymentConfig = await this.$getappConfig();
    },
    goPay(retrieveCode) {
      this.showPayment = false;
      this.retrieveCode = retrieveCode;
      this.$confirm(
        this.$t("searchOrder.wait.des"),
        this.$t("searchOrder.wait.title"),
        {
          confirmButtonText: this.$t("searchOrder.wait.btn"),
          showCancelButton: false,
          center: true,
        }
      )
        .then(() => {
          this.showPayment = false;
          this.loadingShow = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          this.paymenCount = 0;
          this.search(retrieveCode);
        })
        .catch((e) => {
          this.visible = false;
          this.showPayment = true;
        });
    },
    handleClose(done) {
      done();
    },
    errorShow(code) {
      this.paymenCount = this.paymenCount + 1;
      if (this.paymenCount > 30) {
        this.$confirm(
          this.$t("searchOrder.error.des"),
          this.$t("searchOrder.error.title"),
          {
            confirmButtonText: this.$t("searchOrder.error.btn"),
            showCancelButton: false,
            center: true,
          }
        )
          .then(() => {
            this.showPayment = true;
          })
          .catch((e) => {
            this.visible = false;
            this.showPayment = true;
          });
        return;
      }
      setTimeout(() => {
        this.search(code);
      }, 1000);
    },
    search(code) {
      const link = `${this.$origin("/s/payCallBack")}`;
      window.location.href = `${link}?orderId=${code}`;
    },
  },
};
</script>
<style lang="scss">
@media (min-width: 980px) {
  .dialog-warp {
    .el-dialog__wrapper {
      display: flex;
    }

    .el-dialog {
      margin: auto;
      border-radius: 24px;
      // margin-top: 4vh !important;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto !important;
      .el-dialog__header,
      .el-dialog__body {
        padding: 0 !important;
      }

      .el-dialog__headerbtn {
        z-index: 999;

        .el-dialog__close {
          color: #000 !important;
          font-size: 20px !important;
        }
      }
    }
  }
}

@media (max-width: 980px) {
  .dialog-warp {
    .el-dialog {
      width: 100% !important;
      height: 100vh;
      margin: 0 !important;

      .el-dialog__header,
      .el-dialog__body {
        padding: 0 !important;
      }

      .el-dialog__headerbtn {
        z-index: 999;

        .el-dialog__close {
          color: #000 !important;
          font-size: 0.4rem !important;
        }
      }
    }
  }
}
</style>