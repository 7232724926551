import FingerprintJS from '@fingerprintjs/fingerprintjs'
import applePay from '@/assets/images/pay/payments/apple_pay.png'
import card from '@/assets/images/pay/payments/card.png'
import coins from '@/assets/images/pay/payments/coins.png'
import dana from '@/assets/images/pay/payments/dana.png'
import doku from '@/assets/images/pay/payments/doku.png'
import gCash from '@/assets/images/pay/payments/g_cash.png'
import goPay from '@/assets/images/pay/payments/go_pay.png'
import googlePlay from '@/assets/images/pay/payments/google_play.png'
import grab from '@/assets/images/pay/payments/grab.png'
import kakaoPay from '@/assets/images/pay/payments/kakao_pay.png'
import linkAja from '@/assets/images/pay/payments/link_aja.png'
import maya from '@/assets/images/pay/payments/maya.png'
import ovo from '@/assets/images/pay/payments/ovo.png'
import paypal from '@/assets/images/pay/payments/paypal.png'
import shopee from '@/assets/images/pay/payments/shopee.png'
import touchNgo from '@/assets/images/pay/payments/touch_n_go.png'
import truemoney from '@/assets/images/pay/payments/truemoney.png'
import sberpay from '@/assets/images/pay/payments/sberpay.png'
import yoomoney from '@/assets/images/pay/payments/yoomoney.png'
import tinkoffpay from '@/assets/images/pay/payments/tinkoffpay.png'
import ruCard from '@/assets/images/pay/payments/ru_card.png'
import boost from '@/assets/images/pay/payments/boost.png'
import rabbitLine from '@/assets/images/pay/payments/rabbit_line.png'
import payby from '@/assets/images/pay/payments/payby.png'
import alipayHk from '@/assets/images/pay/payments/alipay_hk.png'
import zalopay from '@/assets/images/pay/payments/zalopay.png'
import pix from '@/assets/images/pay/payments/pix.png'
export const currencyMap = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  CNY: '¥',
  AUD: '$',
  CAD: '$',
  CHF: 'Fr',
  SEK: 'kr',
  NZD: '$',
  KRW: '₩',
  HKD: 'HK$',
  NOK: 'kr',
  SGD: 'S$',
  MXN: 'Mex$',
  INR: '₹',
  RUB: '₽',
  BRL: 'R$',
  ZAR: 'R',
  TRY: '₺',
  AED: 'د.إ',
  THB: '฿',
  MYR: 'RM',
  PHP: '₱',
  IDR: 'Rp'
}

//  支付方式
export const paymentMethods = [
  {
    code: 'apple_pay',
    icon: applePay,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'card',
    icon: card,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'devonrexpay',
    icon: card,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'coins',
    icon: coins,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'dana',
    icon: dana,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'doku',
    icon: doku,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'g_cash',
    icon: gCash,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'go_pay',
    icon: goPay,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'google_play',
    icon: googlePlay,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'google_pay',
    icon: googlePlay,
    color: '#1D3041'
  },
  {
    code: 'grab',
    icon: grab,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'grab_pay_ph',
    icon: grab,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'grab_pay_my',
    icon: grab,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'grab_pay_sg',
    icon: grab,
    color: '#1D3041'
  },
  {
    code: 'kakao_pay',
    icon: kakaoPay,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'link_aja',
    icon: linkAja,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'maya',
    icon: maya,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'ovo',
    icon: ovo,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'paypal',
    icon: paypal,
    color: '#1D3041',
    background: '#FFC439'
  },
  {
    code: 'shopee',
    icon: shopee,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'touch_n_go',
    icon: touchNgo,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'truemoney',
    icon: truemoney,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'sberpay',
    icon: sberpay,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'yoomoney',
    icon: yoomoney,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'tinkoff_pay',
    icon: tinkoffpay,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'ru_card',
    icon: ruCard,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'boost',
    icon: boost,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'rabbit_line',
    icon: rabbitLine,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'payby',
    icon: payby,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'alipay_hk',
    icon: alipayHk,
    color: '#1D3041',
    background: ''
  },
  {
    code: 'alipay_hk',
    icon: alipayHk,
    color: '#1D3041'
  },
  {
    code: 'zalopay',
    icon: zalopay,
    color: '#1D3041'
  },
  {
    code: 'pix',
    icon: pix,
    color: '#1D3041'
  }
]
export function payIcon(code) {
  const item = paymentMethods.find(res => res.code === code)
  return item.icon
}
export function utilDeviceType() {
  const userAgent = navigator.userAgent
  const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/
  const isMobile = mobileRegex.test(userAgent)
  let deviceType = 'WEB'
  if (isMobile) {
    deviceType = 'WAP'
  }
  return deviceType
}
export async function environmentInformation() {
  let fingerprint = ''
  try {
    const fp = await FingerprintJS.load()

    const { visitorId } = await fp.get()
    fingerprint = visitorId
  } catch (error) { }
  return {
    userAgent: navigator.userAgent,
    accept: navigator.accept || 'unknown',
    javaEnabled: navigator.javaEnabled ? navigator.javaEnabled() : null,
    cookieEnabled: navigator.cookieEnabled,
    ajaxValidation: typeof XMLHttpRequest !== 'undefined',
    doNotTrack: navigator.doNotTrack ? navigator.doNotTrack === '1' : null,
    colorDepth: screen.colorDepth,
    screenHeight: screen.height,
    screenWidth: screen.width,
    screenOrientation: screen.orientation ? screen.orientation.type : null,
    clientHeight: window.innerHeight,
    clientWidth: window.innerWidth,
    timeZoneOffset: new Date().getTimezoneOffset(),
    language: navigator.language,
    languages: navigator.languages,
    languageBrowser: navigator.language || 'unknown',
    languageUser: navigator.languages ? navigator.languages[0] : 'unknown',
    languageSystem: navigator.language || 'unknown',
    fingerprint: fingerprint, // 获取指纹通常需要第三方库或自定义实现
    os: navigator.userAgent,
    plugins: Array.from(navigator.plugins).map(plugin => plugin.name)
  }
}
export const dateMetaData = {
  en: {
    '1': 'Hour',
    '2': 'Day',
    '3': 'Week',
    '4': 'Month',
    '5': 'Year',
    lifetime: 'Lifetime',
  },
  es: {
    '1': 'Hora',
    '2': 'Día',
    '3': 'Semana',
    '4': 'Mes',
    '5': 'Año',
    lifetime: 'Vida',
  },
  th: {
    '1': 'ชั่วโมง',
    '2': 'วัน',
    '3': 'สัปดาห์',
    '4': 'เดือน',
    '5': 'ปี',
    lifetime: 'ตลอดอายุการใช้งาน',
  },
  pt: {
    '1': 'Hora',
    '2': 'Dia',
    '3': 'Semana',
    '4': 'Mês',
    '5': 'Ano',
    lifetime: 'Vida',
  },
  zh: {
    '1': '小時',
    '2': '天',
    '3': '星期',
    '4': '月',
    '5': '年',
    lifetime: '壽命',
  },
  ko: {
    '1': '시간',
    '2': '낮',
    '3': '주',
    '4': '월',
    '5': '년도',
    lifetime: '일생',
  },
  ms: {
    '1': 'jam',
    '2': 'Hari',
    '3': 'Minggu',
    '4': 'bulan',
    '5': 'tahun',
    lifetime: 'seumur hidup',
  },
  id: {
    '1': 'Jam',
    '2': 'Hari',
    '3': 'Pekan',
    '4': 'Bulan',
    '5': 'Tahun',
    lifetime: 'Seumur hidup',
  },
  in: {
    '1': 'Jam',
    '2': 'Hari',
    '3': 'Pekan',
    '4': 'Bulan',
    '5': 'Tahun',
    lifetime: 'Seumur hidup',
  },
  hi: {
    '1': 'ओ क्लॉक',
    '2': 'दिन',
    '3': 'सप्ताह',
    '4': 'महीना',
    '5': 'वर्ष',
    lifetime: 'जीवनभर',
  },
  fr: {
    '1': 'Heure',
    '2': 'Jour',
    '3': 'Semaine',
    '4': 'Mois',
    '5': 'Année',
    lifetime: 'Durée de vie',
  },
  de: {
    '1': 'Stunde',
    '2': 'Tag',
    '3': 'Woche',
    '4': 'Monat',
    '5': 'Jahr',
    lifetime: 'Lebensdauer',
  },
  it: {
    '1': 'Ora',
    '2': 'Giorno',
    '3': 'Settimana',
    '4': 'Mese',
    '5': 'Anno',
    lifetime: 'Tutta la vita',
  },
  ru: {
    '1': 'Час',
    '2': 'День',
    '3': 'Неделя',
    '4': 'Месяц',
    '5': 'Год',
    lifetime: 'Продолжительность жизни',
  },
  ja: {
    '1': '時間',
    '2': '日',
    '3': '週',
    '4': '月',
    '5': '年',
    lifetime: '一生',
  },
  ar: {
    '1': 'ساعة',
    '2': 'يوم',
    '3': 'أسبوع',
    '4': 'شهر',
    '5': 'سنة',
    lifetime: 'حياة',
  },
  nl: {
    '1': 'Uur',
    '2': 'Dag',
    '3': 'Week',
    '4': 'Maand',
    '5': 'Jaar',
    lifetime: 'Levensduur',
  },
  sv: {
    '1': 'Timme',
    '2': 'Dag',
    '3': 'Vecka',
    '4': 'Månad',
    '5': 'År',
    lifetime: 'Livstid',
  },
  fi: {
    '1': 'Tunnin',
    '2': 'Päivä',
    '3': 'Viikko',
    '4': 'Kuukausi',
    '5': 'vuosi',
    lifetime: 'Elinikäinen',
  },
  da: {
    '1': 'Time',
    '2': 'Dag',
    '3': 'Uge',
    '4': 'Måned',
    '5': 'År',
    lifetime: 'Livstid',
  },
  no: {
    '1': 'Time',
    '2': 'Dag',
    '3': 'Uke',
    '4': 'Måned',
    '5': 'År',
    lifetime: 'Levetid',
  },
  tr: {
    '1': 'Saat',
    '2': 'Gün',
    '3': 'Hafta',
    '4': 'Ay',
    '5': 'Yıl',
    lifetime: 'Ömür boyu',
  },
  hu: {
    '1': 'Óra',
    '2': 'Nap',
    '3': 'Hét',
    '4': 'Hónap',
    '5': 'Év',
    lifetime: 'Élettartam',
  },
  cs: {
    '1': 'Hodina',
    '2': 'Den',
    '3': 'Týden',
    '4': 'Měsíc',
    '5': 'Rok',
    lifetime: 'Celý život',
  },
  pl: {
    '1': 'Godzina',
    '2': 'Dzień',
    '3': 'Tydzień',
    '4': 'Miesiąc',
    '5': 'Rok',
    lifetime: 'Życie',
  },
  vi: {
    '1': 'Giờ',
    '2': 'Ngày',
    '3': 'Tuần',
    '4': 'Tháng',
    '5': 'Năm',
    lifetime: 'Trọn đời',
  },
};
export const uuid = (len, radix) => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  radix = radix || chars.length;
  if (len) {
    for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  } else {
    let r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join('');
};

export const validateFunc = ({ form, inputList }) => {
  let validateResult = []
  Object.keys(form).forEach((key) => {
    const currentInput = inputList.find((obj) => obj.code === key)
    if (!currentInput) return
    const { validationType, isRequired } = currentInput
    const condition = utilValidate(validationType, form[key], isRequired)
    if (condition) {
      currentInput.errorTip = currentInput.warnMessage
      validateResult.push(false)
    } else {
      currentInput.errorTip = ''
      validateResult.push(true)
    }
  })

  //  返回最终是否可以通过校验
  return validateResult.every(item => item)
}
//  邮件校验
export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export function validateEmail(value, isRequired) {
  if (isRequired || value) {
    return !emailRegex.test((value));
  } else {
    return false
  }
}
//  postcode校验
export const postCodeRegex = /^[a-zA-Z0-9]+.?[a-zA-Z0-9]*$/;
export function validatePostCode(value, isRequired) {
  if (isRequired || value) {
    return !postCodeRegex.test(value) || value.length > 15
  } else {
    return false
  }
}
//  默认校验规则
export const defaultRegex = /.+/;
export function validateDefault(value, isRequired) {
  if (isRequired || value) {
    return !defaultRegex.test(value.trim())
  } else {
    return false
  }
}
//  年份校验
const year = new Date().getFullYear()
export const expYearRegex = /^\d{4}$/;
export function validateExpYear(value, isRequired) {
  if (isRequired || value) {
    if (expYearRegex.test(value)) {
      return !(Number(value) >= year)
    } else {
      return true
    }
  } else {
    return false
  }
}
//  月份校验
const month = new Date().getMonth() + 1
export const expMonthRegex = /^(0[1-9]|1[0-2])$/;
export function validateExpMonth(value, isRequired) {
  if (isRequired || value) {
    if (expMonthRegex.test(value)) {
      return !(Number(value) >= month)
    } else {
      return true
    }
  } else {
    return false
  }
}
const fullYear = new Date().getFullYear()
const nowMonth = new Date().getMonth() + 1
//  年月校验
export function validateExpMonthYear(value, isRequired) {
  const arr = value.split('/')
  arr[1] = '20' + (arr[1] || '00')

  const inputTime = new Date(arr[1] + '-' + arr[0]).getTime()
  const nowTime = new Date(fullYear + '-' + ('' + nowMonth).padStart(2, '0')).getTime()

  return !(inputTime >= nowTime)
}

//  表单校验函数
const validateFuncs = {
  email: validateEmail,
  postCode: validatePostCode,
  cardNumber: validateDefault,
  expYear: validateExpYear,
  expMonth: validateExpMonth,
  expMonthYear: validateExpMonthYear,
  cvv: validateDefault,
  default: validateDefault
}
//  校验工具
export const utilValidate = (type, value, isRequired) => {
  return validateFuncs[type](value, isRequired)
}