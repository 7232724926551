
<template>
  <div class="payment" ref="payment">
    <div class="cashier-payment-form" ref="paymentCtx">
      <div :class="['form-item', cardInfoError && 'is-error']">
        <div class="form-item-label">
          <span>{{ text.cardNumber.label }}</span>
          <!-- <span class="require">{{text.required }}</span> -->
        </div>
        <div class="payment-info">
          <div class="cashier-form-input-container">
            <input
              :class="[
                'cardNumber',
                formData.cardNumber.error && 'error-input',
              ]"
              class="cashier-form-input"
              type="text"
              :placeholder="text.cardNumber.label"
              autocomplete="cc-number"
              v-model="formData.cardNumber.value"
            />
          </div>
          <div class="flex-info">
            <div class="cashier-form-input-container">
              <input
                :class="[
                  'expMonthYear',
                  formData.expMonthYear.error && 'error-input',
                ]"
                class="cashier-form-input half-input"
                type="text"
                :placeholder="text.expMonthYear.placeholder"
                autocomplete="cc-exp"
                maxlength="5"
                v-model="formData.expMonthYear.value"
              />
            </div>
            <div class="cashier-form-input-container">
              <input
                :class="['cvv', formData.cvv.error && 'error-input']"
                class="cashier-form-input half-input"
                type="text"
                :placeholder="text.cvv.placeholder"
                autocomplete="cc-csc"
                v-model="formData.cvv.value"
              />
            </div>
          </div>
          <span class="form-item-error" v-if="showError">{{
            cardInfoMessage
          }}</span>
        </div>
      </div>
      <div :class="['form-item', formData.fullName.error && 'is-error']">
        <div class="form-item-label">
          <span>{{ text.fullName.label }}</span>
          <!-- <span class="require" v-if="showError">{{ text.required }}</span> -->
        </div>
        <div class="cashier-form-input-container">
          <input
            :class="['fullName', formData.fullName.error && 'error-input']"
            class="cashier-form-input error-input"
            type="text"
            :placeholder="text.fullName.placeholder"
            autocomplete="cc-name"
            v-model="formData.fullName.value"
          />
        </div>
        <span class="form-item-error" v-if="showError">{{
          formData.fullName.message
        }}</span>
      </div>
      <div>
        <button
          class="button_pay_primary"
          :style="{
            background: isChecked ? '' : '#C8C9C8',
            cursor: isChecked ? '' : 'not-allowed',
            opacity: isChecked ? '' : 1,
          }"
          @click="handlePay"
        >
          {{ text.buttonTxt }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Cleave from "cleave.js";
import { validateExpMonthYear } from "@/utils/pay";
export default {
  props: {
    isChecked: {
      type: Boolean,
      default: true,
    },
    checkedInput: {
      type: Boolean,
      default: true,
    },
    payFormData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    payList: {
      type: Object,
      default: () => {
        return {};
      },
    },
    cardInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showError: true,
      cardChecked: false,
      formData: {
        cardNumber: {
          value: "",
          instance: {},
          type: "unknown", // 号段类型
          error: false,
          message: "",
        },
        expMonthYear: {
          value: "",
          error: false,
          message: "",
        },
        cvv: {
          value: "",
          error: false,
          message: "",
        },
        fullName: {
          value: "",
          error: false,
          message: "",
        },
      },
    };
  },
  computed: {
    paymentLink() {
      const findItem = JSON.parse(JSON.stringify(this.cardInfo));
      const payItemBox = JSON.parse(JSON.stringify(this.payList));
      delete payItemBox.paymentMethodList;
      let paymentItem = JSON.parse(
        JSON.stringify({
          ...findItem,
          ...payItemBox,
          isSandbox: true,
          metadata: {},
        })
      );
      delete paymentItem.paymentFormList;
      paymentItem.paymentInfo = {};
      const itemFormData = this.cardPayChecked(false);
      findItem.paymentFormList.forEach((formItem) => {
        paymentItem.paymentInfo[formItem.code] =
          this.formData[formItem.code] || formItem.preFillingValue || "";
      });
      Object.keys(itemFormData).forEach((code) => {
        paymentItem.paymentInfo[code] = itemFormData[code] || "";
      });
      Object.keys(this.payFormData).forEach((code) => {
        paymentItem.paymentInfo[code] = this.payFormData[code] || "";
      });
      if(this.payFormData.userEmail) {
        paymentItem.paymentInfo.email = this.payFormData.userEmail
      }
      let paymentLinkJson = btoa(JSON.stringify(paymentItem));
      paymentLinkJson =
        paymentLinkJson.slice(0, 1) + "=" + paymentLinkJson.slice(1);
      return findItem.paymentLink + `?${paymentLinkJson}`;
    },
    cardInfoError() {
      return (
        this.formData.cardNumber.error ||
        this.formData.expMonthYear.error ||
        this.formData.cvv.error
      );
    },
    cardInfoMessage() {
      return (
        this.formData.cardNumber.message ||
        this.formData.expMonthYear.message ||
        this.formData.cvv.message
      );
    },
    text() {
      return this.$t("payDialog.card");
    },
  },
  methods: {
    cardPayChecked(type = true) {
      let cardChecked = true;
      try {
        const { cardNumber, expMonthYear, cvv, fullName } = this.formData;
        //  验证卡号
        if (cardNumber.type === "unknown") {
          cardNumber.error = true;
          cardNumber.message = this.text.cardNumber.none;
          cardChecked = false;
        } else if (
          cardNumber.instance.getRawValue().length <
          cardNumber.instance.properties.maxLength
        ) {
          cardNumber.error = true;
          cardNumber.message = this.text.cardNumber.error;
          cardChecked = false;
        } else {
          cardNumber.error = false;
          cardNumber.message = "";
        }

        //  验证日期
        if (!expMonthYear.value) {
          const status = validateExpMonthYear(expMonthYear.value, true);
          expMonthYear.error = status;
          expMonthYear.message = this.text.expMonthYear.error;
          cardChecked = status;
        } else {
          expMonthYear.error = false;
          expMonthYear.message = "";
        }

        const month = expMonthYear.value.split("/")[0];
        const year = expMonthYear.value.split("/")[1];

        if (!year) {
          expMonthYear.error = true;
          expMonthYear.message = this.text.expMonthYear.error;
          cardChecked = false;
        } else {
          const status = validateExpMonthYear(expMonthYear.value, true);
          expMonthYear.error = status;
          expMonthYear.message = status ? this.text.expMonthYear.none : "";
        }

        //  验证cvv
        this.formData.cvv.value = this.formData.cvv.value.replace(/\D/g, "");
        if (
          this.formData.cvv.value.length < cvv.instance.properties.maxLength
        ) {
          cvv.error = true;
          cardChecked = false;
          cvv.message = this.text.cvv.error;
        } else {
          cvv.error = false;
          cvv.message = "";
        }
        const names = fullName.value.split(" ");
        const firstName = names.splice(0, 1).join();
        const lastName = names
          .splice(0, Infinity)
          .filter((i) => !!i)
          .reduce((p, n) => {
            return p + " " + n;
          }, "")
          .trim();

        //  验证姓名
        if (!this.formData.fullName.value) {
          this.formData.fullName.error = true;
          this.formData.fullName.message = this.text.fullName.none;
          cardChecked = false;
        } else if (!firstName || !lastName) {
          this.formData.fullName.error = true;
          this.formData.fullName.message = this.text.fullName.error;
          cardChecked = false;
        } else {
          this.formData.fullName.error = false;
          this.formData.fullName.message = "";
        }
        if (!fullName.value) {
          fullName.error = true;
          cardChecked = false;
          fullName.message = this.text.fullName.none;
        } else if (!firstName || !lastName) {
          fullName.error = true;
          cardChecked = false;
          fullName.message = this.text.fullName.error;
        } else {
          fullName.error = false;
          fullName.message = "";
        }

        const data = {
          cardNumber: cardNumber.instance.getRawValue(),
          expMonth: month,
          expYear: "20" + year,
          cvv: cvv.value,
          firstName: firstName,
          lastName: lastName,
        };
        if (type) {
          this.cardChecked = cardChecked;
        }
        return data;
      } catch (error) {
        console.log(error);
        return {};
      }
    },
    init() {
      const cardNumberInit = () => {
        return new Cleave(".cardNumber", {
          creditCard: true,
          onCreditCardTypeChanged: (type) => {
            this.formData.cardNumber.type = type;

            if (type === "unknown") return;
            if (type === "amex") {
              this.formData.cvv.instance.destroy();
              this.formData.cvv.instance = cvvInit(4);
            } else {
              this.formData.cvv.instance.destroy();
              this.formData.cvv.instance = this.formData.cvv.instance =
                cvvInit(3);
            }
          },
          onValueChanged: (obj) => {
            if (
              this.formData.cardNumber.type === "unknown" &&
              obj.target.rawValue
            ) {
              this.formData.cardNumber.error = true;
              this.formData.cardNumber.message = this.text.cardNumber.none;
            } else if (
              obj.target.rawValue.length <
                this.formData.cardNumber.instance.properties.maxLength &&
              obj.target.rawValue
            ) {
              this.formData.cardNumber.error = true;
              this.formData.cardNumber.message = this.text.cardNumber.error;
            } else {
              this.formData.cardNumber.error = false;
              this.formData.cardNumber.message = "";
            }
            this.formData.cardNumber.value = obj.target.value;
          },
        });
      };

      const expMonthYearInit = () => {
        return new Cleave(".expMonthYear", {
          date: true,
          datePattern: ["m", "y"],
          onValueChanged: (obj) => {
            if (obj.target.value) {
              const year = obj.target.value.split("/")[1];
              if (!year) {
                this.formData.expMonthYear.error = true;
                this.formData.expMonthYear.message =
                  this.text.expMonthYear.error;
              } else {
                const status = validateExpMonthYear(obj.target.value, true);
                this.formData.expMonthYear.error = status;
                this.formData.expMonthYear.message = status
                  ? this.text.expMonthYear.none
                  : "";
              }
            } else {
              this.formData.expMonthYear.error = false;
              this.formData.expMonthYear.message = "";
            }
            this.formData.expMonthYear.value = obj.target.value;
          },
        });
      };

      const cvvInit = (length) => {
        return new Cleave(".cvv", {
          blocks: [length],
          onValueChanged: (obj) => {
            obj.target.value = obj.target.value.replace(/\D/g, "");
            if (
              obj.target.value.length <
                this.formData.cvv.instance.properties.maxLength &&
              obj.target.value
            ) {
              this.formData.cvv.error = true;
              this.formData.cvv.message = this.text.cvv.error;
            } else {
              this.formData.cvv.error = false;
              this.formData.cvv.message = "";
            }
            this.formData.cvv.value = obj.target.value;
          },
        });
      };

      const fullNameInit = (length) => {
        return new Cleave(".fullName", {
          delimiter: "",
          blocks: [length],
          onValueChanged: (obj) => {
            this.formData.fullName.value = obj.target.value;
            //  验证姓名
            const names = this.formData.fullName.value.split(" ");
            const firstName = names.splice(0, 1).join();
            const lastName = names
              .splice(0, Infinity)
              .filter((i) => !!i)
              .reduce((p, n) => {
                return p + " " + n;
              }, "")
              .trim();

            if (!this.formData.fullName.value) {
              this.formData.fullName.error = true;
              this.formData.fullName.message = this.text.fullName.none;
            } else if (!firstName || !lastName) {
              this.formData.fullName.error = true;
              this.formData.fullName.message = this.text.fullName.error;
            } else {
              this.formData.fullName.error = false;
              this.formData.fullName.message = "";
            }
          },
        });
      };
      this.formData.cardNumber.instance = cardNumberInit();
      this.formData.expMonthYear.instance = expMonthYearInit();
      this.formData.cvv.instance = cvvInit(3);
      this.formData.fullName.instance = fullNameInit(100);
    },
    //支付
    handlePay() {
      if (!this.isChecked) {
        return;
      }
      let paymentLink = this.paymentLink;
      this.$emit("checkeFormData");
      this.cardPayChecked();
      paymentLink = this.paymentLink;
      if (this.checkedInput && this.isChecked && this.cardChecked) {
        this.$emit("goPay", paymentLink);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped lang="scss">
@media (min-width: 980px) {
  .payment {
    transition: all 0.3s;
    .cashier-payment-form {
      padding: 0px 5px;
      padding-bottom:0;
      .form-item {
        margin-bottom: 16px;

        .form-item-label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #1a1a1ab2;
          font-size: 13px;

          .require {
            display: none;
            font-size: 11px;
          }
        }

        .cashier-form-input-container {
          position: relative;
          .cashier-form-input {
            display: block;
            position: relative;
            width: 100%;
            height: 44px;
            border-radius: 6px;
            margin-top: 4px;
            border: none;
            background: #fff;
            z-index: 2;
           text-indent: 20px;
            box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
              0 1px 1.5px 0 rgba(0, 0, 0, 0.05);

            &:focus {
              box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.7),
                0 1px 1px 0 rgba(0, 0, 0, 0.07),
                0 0 0 4px rgba(50, 151, 211, 0.3) !important;
              z-index: 3;
            }
          }
          .error-icon {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 5;
          }
        }

        .payment-info {
          .flex-info {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          }

          .cardNumber {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          .expMonthYear {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            margin-top: 0;
          }
          .cvv {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
            margin-top: 0;
          }
        }

        .form-item-error {
          display: block;
          color: #dc2727;
          font-size: 13px;
          height: 0;
          opacity: 0;
          transition: all 0.3s ease;
        }
      }

      .is-error {
        .form-item-label {
          .require {
            display: block;
            color: #dc2727;
          }
        }
        .cashier-form-input-container {
          .cashier-form-input {
            box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
              0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
            z-index: 1;
          }
          .error-input {
            box-shadow: 0 0 0 1px #ef9896, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
              0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
            z-index: 2;
          }
        }
        .form-item-error {
          margin-top: 4px;
          height: 18px;
          opacity: 1;
        }
      }

      .button_pay_primary {
        display: block;
        border: none;
        width: 100%;
        border-radius: 8px;
        padding: 10px;
        // margin-top: 0.3rem;
        margin-bottom: 10px;
        font-size: 18px;
        font-family: Manrope-Medium, Manrope;
        font-weight: 500;
        color: #ffffff;
        background: #121212;

        &:hover {
          cursor: pointer;
          background: #3e5569;
        }
      }
    }
    .ssl-logo {
      padding: 0 5px;
      margin-top: 0.12rem !important;
    }
  }
}
@media (max-width: 980px) {
  .payment {
    transition: all 0.3s;
    .cashier-payment-form {
      padding: 16px 0;
      padding-bottom: 0;
      .form-item {
        margin-bottom: 16px;

        .form-item-label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #1a1a1ab2;
          font-size: 13px;

          .require {
            display: none;
            font-size: 11px;
          }
        }

        .cashier-form-input-container {
          position: relative;
          .cashier-form-input {
            display: block;
            position: relative;
            width: 100%;
            height: 44px;
            text-indent: 12px;
            border-radius: 6px;
            margin-top: 4px;
            border: none;
            background: #fff;
            z-index: 2;
            -webkit-appearance: none;
            box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
              0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
            &:focus {
              box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.7),
                0 1px 1px 0 rgba(0, 0, 0, 0.07),
                0 0 0 4px rgba(50, 151, 211, 0.3) !important;
              z-index: 3;
            }
          }
          .error-icon {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 5;
          }
        }

        .payment-info {
          .flex-info {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          }

          .cardNumber {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          .expMonthYear {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            margin-top: 0;
          }
          .cvv {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;
            margin-top: 0;
          }
        }

        .form-item-error {
          display: block;
          color: #dc2727;
          font-size: 13px;
          height: 0;
          opacity: 0;
          transition: all 0.3s ease;
        }
      }

      .is-error {
        .form-item-label {
          .require {
            display: block;
            color: #dc2727;
          }
        }
        .cashier-form-input-container {
          .cashier-form-input {
            box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
              0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
            z-index: 1;
          }
          .error-input {
            box-shadow: 0 0 0 1px #ef9896, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
              0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
            z-index: 2;
          }
        }
        .form-item-error {
          margin-top: 4px;
          height: 18px;
          opacity: 1;
        }
      }

      .button_pay_primary {
        display: block;
        border: none;
        width: 100%;
        border-radius: 0.16rem;
        padding: 0.2rem;
        // margin-top: 0.3rem;
        margin-bottom: 0.2rem;
        font-size: 0.36rem;
        font-family: Manrope-Medium, Manrope;
        font-weight: 500;
        color: #ffffff;
        background: #121212;

        &:active {
          cursor: pointer;
          background: #3e5569;
        }
      }
    }
    .ssl-logo {
      padding: 0 5px;
      margin-top: 0.12rem !important;
    }
  }
}
</style>