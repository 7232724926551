module.exports = {
 conversion: {
  trySample: "Try a Sample",
  tryTips: "One click opens the door to global language interoperability, a language bridge across borders, allowing you to easily break through the language barrier and navigate the information ocean!",
  title: "Target Language",
  original: "Original language",
  copy: 'Copy',
  downAudio: 'Download Audio',
  copySuccess: 'Copy Success',
  translate: 'Translate',
  downSuccess: 'The audio has been downloaded for you',
  errorLang: 'Please select your language',
  "translationSelect": "Please select the language of translation",
  translateError: "Functions are being improved",
  "placeholder": "Please select a language. Multiple selections are allowed.",

 },
 jsonEdit: {
  config: 'Config',
  conversion: "Conversion",
  form: {
   errorTips: "The json content is incorrect",
   add: 'Add',
   ignore: 'Ignore',
   keyIgnore: 'Key Ignore',
   translate: "Translate",
   ignoreplaceholder: "Please enter the ignored content such as: .png",
   ignorekeyplaceholder: "Please enter the ignored content such as: .png",
  }
 },
 "searchOrder": {
  "wait": {
   "title": "Has the transaction been completed?",
   "des": "If you have completed the payment, please click the button below to obtain the transaction result.",
   "btn": "Completed Payment"
  },
  "error": {
   "title": "Transaction has been declined",
   "des": "Please check your bill to confirm if the transaction has been completed, or try again.",
   "btn": "RETRY"
  }
 },
 "payDialog": {
  "checkedError": "Please read and confirm the agreement",
  "paymentMethod": "Payment method",
  "DiscountBtn": "Discount Trial",
  "paymentBtn": "Payment",
  "buttonTxt": "Continue to payment",
  "paymentTitle": "Recurring payment details",
  "paymentDes": "Subscription will be renewed for {price} per {subDate}. Cancel any time",
  "paymentTag": "Start date {currentDay}",
  "lookupTitle": "GPS Location Locked",
  "lookupDes": "connecting to the phone...",
  "lookupCarrier": "Carrier",
  "lookupCountry": "Country",
  "lookupCity": "City",
  "lookupLocation": "Location",
  "lookupLocationResult": "DEFINED",
  "lookupUnlock": "unlock",
  "headerCreateTitle": "Unlock account",
  "headerOtherTitle": "Unlock full results",
  "footerPayDes": "{appName} has served more than 10 million users and received millions of ratings！<div style='color:#616366;'>The best location tracking tool</div>",
  "footerButton": "Discount Trial",
  "footerTagOff": "% OFF",
  "headerCreateDes": "Unlock your account to locate <span class=\"highlight\">GPS unlimited times</span>",
  "headerLocatePhone": "The location of {phone}",
  "card": {
   "buttonTxt": "Start Payment",
   "required": "Required",
   "cardNumber": {
    "label": "Bank card information",
    "placeholder": "card number",
    "error": "Your card number is incomplete",
    "none": "Your card number is invalid"
   },
   "expMonthYear": {
    "label": "Bank card information",
    "placeholder": "Month/Year",
    "error": "Your bank card's expiration date is incomplete",
    "none": "Your bank card has expired"
   },
   "cvv": {
    "label": "Bank card information",
    "placeholder": "CVV/CVC",
    "error": "Your bank card security code is incomplete",
    "none": "Your bank card security code is incomplete"
   },
   "fullName": {
    "label": "Cardholder's Name",
    "placeholder": "Bank card full name",
    "error": "You must use a space to separate the first and last name.",
    "none": "Your name is incomplete"
   }
  },
 },
 "btn": {
  "subscribeNow": "Subscribe Now",
  "PayNow": "Pay now"
 },
 "tips": {
  "tipsInfo": {
   "title": "Total {price}",
   "paymentTitle": "Recurring payment details",
   "date": "Start date: {subDate}",
   "total": "Total",
   "emailTip": "Enter and Check Your Email!",
   "thenDesc": "Then {price} total per {date}",
   "paymentDes": "Subscription will be renewed for {price} per {subDate}. Cancel any time",
   "paymentTag": "Start date {currentDay}"
  },
  "subscribe": {
   "descr": "You need to pay {money} for {trialDate}, and then the system will charge you {price}/{subDate}. Cancel anytime",
   "descr1": "*Unless canceled during the trial period, you will be upgraded to a {subDate} subscription and billed at {price}",
   "description": "*Please note that this transaction will appear on your bank statement as {billingDesc}",
   "buttonTxt": "Subscribe Now",
   "protocol": "I have read the <a href='{subscriptionTermsUrl}' target='_blank'>Subscription Terms</a>."
  },
  "oneTime": {
   "protocol": "I have read the User Agreement: <a href='{userAgreementUrl}' target='_blank'>User Agreement</a>.",
   "descr": "You will pay {price} to unlock all features, valid until {subDate}",
   "buttonTxt": "Unlock All Features Now",
   "description": "*Please note that this transaction will appear on your bank statement as {billingDesc}"
  }
 },
 "message": {
  "emailPlaceholder": "Billing Email",
  "emailError": "Please fill in the correct billing email address"
 }
}