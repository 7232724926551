<template>
  <div>
    <template v-if="priceInfo.priceInfo.offerType === 0">
      <p class="tip"
      v-if="$t('tips.oneTime.descr')" v-html="$t('tips.oneTime.descr', {
        price: formatPrice(priceInfo.priceInfo.price,priceInfo.priceInfo.currency,$i18n.locale),
        money: formatPrice(priceInfo.priceInfo.showPrice,priceInfo.priceInfo.currency, $i18n.locale),
        trialDate:utilComputedSubDate(priceInfo.priceInfo['tryoutDurationUnit'], priceInfo.priceInfo['tryoutDuration']),
        subDate:utilComputedSubDate(priceInfo.priceInfo['durationUnit'], priceInfo.priceInfo['duration']),
      })" />
      <p class="tip check-box" v-if="$t('tips.oneTime.protocol')" @click="changeChecked">
        <span :class="['check-inner', isChecked && 'checked-inner']" />
        <span v-html="$t('tips.oneTime.protocol', configTips)"></span>
      </p>
      <span class="form-item-error" v-if="showErrorChecked">{{
          $t("payDialog.checkedError")
        }}</span>
      <p class="tip"
      v-if="$t('tips.oneTime.description')"
       v-html="$t('tips.oneTime.description', configTips)" />
    </template>
    <template v-else-if="priceInfo.priceInfo.offerType !== 0">
      <p class="tip"
      v-if="$t('tips.subscribe.descr')"
      v-html="$t('tips.subscribe.descr', {
        trialDate:utilComputedSubDate(priceInfo.priceInfo['tryoutDurationUnit'], priceInfo.priceInfo['tryoutDuration']),
        subDate:utilComputedSubDate(priceInfo.priceInfo['durationUnit'], priceInfo.priceInfo['duration']),
        price: formatPrice(priceInfo.priceInfo.price,priceInfo.priceInfo.currency,$i18n.locale),
        money: formatPrice(priceInfo.priceInfo.showPrice,priceInfo.priceInfo.currency, $i18n.locale),
      })" />
      <p class="tip"
      v-if="$t('tips.subscribe.descr1')"
       v-html="$t('tips.subscribe.descr1', {
        trialDate:utilComputedSubDate(priceInfo.priceInfo['tryoutDurationUnit'], priceInfo.priceInfo['tryoutDuration']),
        subDate:utilComputedSubDate(priceInfo.priceInfo['durationUnit'], priceInfo.priceInfo['duration']),
        price: formatPrice(priceInfo.priceInfo.price,priceInfo.priceInfo.currency,$i18n.locale),
        money: formatPrice(priceInfo.priceInfo.showPrice,priceInfo.priceInfo.currency, $i18n.locale),
      })" />
      <p class="tip check-box"
      v-if="$t('tips.subscribe.protocol')" @click="changeChecked">
        <span :class="['check-inner', isChecked && 'checked-inner']" />
        <span v-html="$t('tips.subscribe.protocol', configTips)"></span>
      </p>
      <span class="form-item-error" v-if="showErrorChecked">{{
          $t("payDialog.checkedError")
        }}</span>
      <p class="tip"
      v-if="$t('tips.subscribe.description')"
       v-html="$t('tips.subscribe.description', configTips)" />
    </template>
  </div>
</template>
<script>
import payMixins from "@/mixin/pay"
export default {
  mixins:[payMixins],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showErrorChecked: {
      type: Boolean,
      default: false,
    },
    priceInfo: {
      type: Object,
      default: () => {
        return {
          productsInfo: {}
        }
      }
    },
    configTips: {
      type: Object,
      default: () => {
        return {
        }
      }
    },
  },
  computed: {
    isChecked() {
      return this.value
    }
  },
  methods: {
    changeChecked() {
      this.$emit('input', !this.value)
      this.$emit('change', !this.value)
    }
  }
}
</script>


<style lang="scss">
@media (min-width: 980px) {
    .el-popper{
      width: auto !important;
    }
  .check-box {
    // display: flex !important;
    cursor: pointer;
    .check-inner {
      display: inline-block;
      width: 16px;
      height: 16px;
      background: #FFFFFF;
      border-radius: 3px;
      border: 1px solid #C8C9C8;
      line-height: 26px;
      flex-shrink: 0;
      margin-right: 4px;
      transform: translateY(3px);
    }

    .checked-inner {
      border: 0 !important;
      background: url('@/assets/images/pay/checked.png');
      background-size: 100% 100%;
    }
  }

  .tip {
    width: 100%;
    display: block;
    margin-top: 4px;
    font-family: Manrope-Regular, Manrope;
    font-weight: 400;
    color: #7A7A7A;
    line-height: 16px;
    font-size: 12px;
  }
  .form-item-error {
      display: block;
      color: #dc2727;
      font-size: 13px;
    }
}


@media (max-width: 980px) {
    .form-item-error {
      display: block;
      color: #dc2727;
      font-size: 13px;
    }
  .check-box {
    // display: flex !important;
    cursor: pointer;
    .check-inner {
      display: inline-block;
      width: 0.24rem;
      height: 0.24rem;
      background: #FFFFFF;
      border-radius: 0.06rem;
      border: 1px solid #C8C9C8;
      line-height: 0.32rem;
      flex-shrink: 0;
      margin-right: 0.08rem;
      transform: translateY(0.02rem);
    }

    .checked-inner {
      border: 0 !important;
      background: url('@/assets/images/pay/checked.png');
      background-size: 100% 100%;
    }
  }

  .tip {
    width: 100%;
    display: block;
    margin-top: 0.1rem;
    font-family: Manrope-Regular, Manrope;
    font-weight: 400;
    color: #7A7A7A;
    line-height: 0.26rem;
    font-size: 0.24rem;
  }
}
</style>
