var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.priceInfo.priceInfo.offerType === 0)?[(_vm.$t('tips.oneTime.descr'))?_c('p',{staticClass:"tip",domProps:{"innerHTML":_vm._s(_vm.$t('tips.oneTime.descr', {
      price: _vm.formatPrice(_vm.priceInfo.priceInfo.price,_vm.priceInfo.priceInfo.currency,_vm.$i18n.locale),
      money: _vm.formatPrice(_vm.priceInfo.priceInfo.showPrice,_vm.priceInfo.priceInfo.currency, _vm.$i18n.locale),
      trialDate:_vm.utilComputedSubDate(_vm.priceInfo.priceInfo['tryoutDurationUnit'], _vm.priceInfo.priceInfo['tryoutDuration']),
      subDate:_vm.utilComputedSubDate(_vm.priceInfo.priceInfo['durationUnit'], _vm.priceInfo.priceInfo['duration']),
    }))}}):_vm._e(),(_vm.$t('tips.oneTime.protocol'))?_c('p',{staticClass:"tip check-box",on:{"click":_vm.changeChecked}},[_c('span',{class:['check-inner', _vm.isChecked && 'checked-inner']}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('tips.oneTime.protocol', _vm.configTips))}})]):_vm._e(),(_vm.showErrorChecked)?_c('span',{staticClass:"form-item-error"},[_vm._v(_vm._s(_vm.$t("payDialog.checkedError")))]):_vm._e(),(_vm.$t('tips.oneTime.description'))?_c('p',{staticClass:"tip",domProps:{"innerHTML":_vm._s(_vm.$t('tips.oneTime.description', _vm.configTips))}}):_vm._e()]:(_vm.priceInfo.priceInfo.offerType !== 0)?[(_vm.$t('tips.subscribe.descr'))?_c('p',{staticClass:"tip",domProps:{"innerHTML":_vm._s(_vm.$t('tips.subscribe.descr', {
      trialDate:_vm.utilComputedSubDate(_vm.priceInfo.priceInfo['tryoutDurationUnit'], _vm.priceInfo.priceInfo['tryoutDuration']),
      subDate:_vm.utilComputedSubDate(_vm.priceInfo.priceInfo['durationUnit'], _vm.priceInfo.priceInfo['duration']),
      price: _vm.formatPrice(_vm.priceInfo.priceInfo.price,_vm.priceInfo.priceInfo.currency,_vm.$i18n.locale),
      money: _vm.formatPrice(_vm.priceInfo.priceInfo.showPrice,_vm.priceInfo.priceInfo.currency, _vm.$i18n.locale),
    }))}}):_vm._e(),(_vm.$t('tips.subscribe.descr1'))?_c('p',{staticClass:"tip",domProps:{"innerHTML":_vm._s(_vm.$t('tips.subscribe.descr1', {
      trialDate:_vm.utilComputedSubDate(_vm.priceInfo.priceInfo['tryoutDurationUnit'], _vm.priceInfo.priceInfo['tryoutDuration']),
      subDate:_vm.utilComputedSubDate(_vm.priceInfo.priceInfo['durationUnit'], _vm.priceInfo.priceInfo['duration']),
      price: _vm.formatPrice(_vm.priceInfo.priceInfo.price,_vm.priceInfo.priceInfo.currency,_vm.$i18n.locale),
      money: _vm.formatPrice(_vm.priceInfo.priceInfo.showPrice,_vm.priceInfo.priceInfo.currency, _vm.$i18n.locale),
    }))}}):_vm._e(),(_vm.$t('tips.subscribe.protocol'))?_c('p',{staticClass:"tip check-box",on:{"click":_vm.changeChecked}},[_c('span',{class:['check-inner', _vm.isChecked && 'checked-inner']}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('tips.subscribe.protocol', _vm.configTips))}})]):_vm._e(),(_vm.showErrorChecked)?_c('span',{staticClass:"form-item-error"},[_vm._v(_vm._s(_vm.$t("payDialog.checkedError")))]):_vm._e(),(_vm.$t('tips.subscribe.description'))?_c('p',{staticClass:"tip",domProps:{"innerHTML":_vm._s(_vm.$t('tips.subscribe.description', _vm.configTips))}}):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }