<template>
  <div>
    <div class="maks" v-loading="loading" v-if="loading"></div>
    <div
      v-for="(component, name) in components"
      :key="name"
      class="layout-items"
    >
      <component
        :is="component.component"
        @goPay="goPay"
        v-bind="component.props"
      ></component>
    </div>
    <payment :orderId="payment.id" v-model="payment.show" v-if="payment.show" />
  </div>
</template>

<script>
import payment from "@/components/payment.vue";
export default {
  components: { payment },
  data() {
    return {
      payment: {
        id: "",
        show: false,
      },
      components: {},
      loading: true,
    };
  },
  methods: {
    goPay(id) {
      this.payment = {
        id,
        show:true
      }
    },
  },
  async created() {
    try {
      let host = this.$extractDomain(window.location.hostname);
      // if (host == 'localhost') host = sessionStorage.getItem('SiteEntrance')
      // let result = await this.$api.getPageInfo({ host, page: 'index' })
      // const componentConfig = result.data.content
      // console.log(result,"here result>>>")
      let lang = this.$route.params.lang || "en";
      if (lang === "id") {
        host = `${host}/${lang}`;
        this.$i18n.locale = this.$route.params.lang;
      }
      const response = await import(`../site-json/${host}/index.json`);
      const componentConfig = await this.$replacePlaceholders(response.default);
      this.loading = false;
      for (const [name, config] of Object.entries(componentConfig)) {
        this.$set(this.components, name, {
          component: name,
          props: config.props,
        });
      }
    } catch (error) {
      console.error("Failed to load component configuration:", error);
    }
  },
};
</script>
<style lang="scss">
.layout-items {
  // margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
